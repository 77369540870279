
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { Notice } from '@/interface/interface';
import BorderHeader from '@/pages/my-page/board/board-header.vue';
import BoardMixin from '@/pages/my-page/board/board-mixin.vue';
import Comments from '@/pages/my-page/board/comment.vue';
import Uploader from '@/components/file-uploader.vue';
@Component({
  components: {
    BorderHeader,
    BoardMixin,
    Comments,
    Uploader,
  }
})
export default class BoardRead extends Mixins(BoardMixin) {
  private _id: string | null = null;
  private doc: Notice | null = null;
  private prevDoc: Notice | null = null;
  private nextDoc: Notice | null = null;
  private isPageFirst = false;
  private isPageLast = false;
  private likeLoading = false;
  private observer?: IntersectionObserver;

  async created(): Promise<void> {
    this.loading = true;
    const { _id } = this.$route.params;
    this._id = _id;
    // await new Promise((resolve) => setTimeout(resolve, 122000));
    await this.load();
    await this.loadImage();
    this.loading = false;
  }

  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private async load() {
    if (this._id) this.doc = await this.getContent(this._id);
    await new Promise((resolve) => setTimeout(resolve, 300));
    this.focus();
  }

  private focus(table?: HTMLTableElement) {
    if (!this.isNav) {
      const ref = this.$refs.header as Vue | null;
      if (ref) {
        const { $el } = ref;
        if ($el) $el.scrollIntoView(false);
      }
    } else {
      if (table) table.scrollIntoView(true);
    }
  }

  private async likeAction() {
    if (!this.doc) return;
    this.likeLoading = true;
    if (!this._id) return;
    const doc = await this.like(this._id);
    if (doc) {
      const { isLike, likeCount } = doc;
      this.doc.isLike = isLike;
      this.doc.likeCount = likeCount;
    }
    await this.$nextTick();
    this.likeLoading = false;
  }

  private intersectionCallback(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
    entries.forEach(async entry => {
      const { isIntersecting, target } = entry;
      const node = target as HTMLDivElement;
      const src = node.getAttribute('data-src');
      const loading = node.getAttribute('data-loading');
      if (isIntersecting) {
        if (!loading) {
          if (!src) return;
          const image = new Image();
          image.src = src;
          await new Promise((resolve) => {
            image.onload = async () => {
              node.innerHTML = '';
              const { width: w, height: h } = image;
              const { width, height } = this.resize({ w, h }, {});
              node.style.width = width;
              node.style.height = height;
              node.style.backgroundSize = 'contain';
              node.style.backgroundImage = `url('${src}')`;
              // if (flag) node.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
              return resolve(true);
            }
            image.onerror = () => {
              // node.style.height = '0px';
              // node.innerText = `NO IMAGE`;
              // node.style.color = 'black';
              // node.style.backgroundImage = '';
              const parent = node.parentElement;
              if (parent) parent.removeChild(node);
              return resolve(false);
            };
          });
        }
        node.style.opacity = '1';
      } else {
        node.style.opacity = '0';
      }
    });
  }

  private async loadImage() {
    let flag = true;
    document.addEventListener('keyup', () => {
      flag = false;
    }, { once: true });

    document.addEventListener('mousemove', () => {
      flag = false;
    }, { once: true });

    document.addEventListener('click', () => {
      flag = false;
    }, { once: true });

    const contents = this.$refs.contents;
    if (contents) {
      const ref = contents as HTMLDivElement;
      await this.$nextTick();
      const nodes = ref.querySelectorAll(`.bg-images`);
      if (nodes.length) {
        this.observer = new IntersectionObserver(this.intersectionCallback, { root: null, rootMargin: '0px', threshold: 0.0 });
        for (const node of Array.from(nodes) as HTMLDivElement[]) {
          const src = node.getAttribute('data-src');
          this.observer.observe(node);
          node.style.transition = `background-image 0.2s ease-in-out, opacity 0.5s ease-in-out`;
          if (!src) break;
        }
      }
    }
  }

  private resize({ w, h }: { w: number, h: number }, { wUnit = 'px', hUnit = 'px' }: { wUnit?: string, hUnit?: string }) {
    const maxWidth = 600;
    const maxHeight = 800;
    const ratio = Math.min(maxWidth / w, maxHeight / h);
    const width = `${w * (w > maxWidth ? ratio : 1)}${wUnit}`;
    const height = `${h * (h > maxHeight ? ratio : 1)}${hUnit}`;
    return { width, height };
  }

  removeConfirm(_id: string, evt?: Event): boolean {
    if (this.deleteFlag) {
      this.remove(_id);
      this.deleteFlag = false;
      return this.deleteFlag;
    }
    return this.deleteFlag = true;
  }

  private ready({ docs, next, prev, page, totalPages, limit, table }:  { docs: Notice[], next: Notice | null, prev: Notice | null, page: number, totalPages: number, limit: number, table: HTMLTableElement | null }) {
    this.prevDoc = prev;
    this.nextDoc = next;
    if (table) this.focus(table);
    const currentIndex = docs.findIndex((doc) => doc._id === this._id);
    if (currentIndex === 0) this.isPageFirst = true;
    else if (currentIndex === docs.length - 1) this.isPageLast = true;
    // const hasPrev = !!docs[currentIndex - 1];
    // if (!hasPrev) {
    //   this.navigationPage = page - 1;
    // }
    // const hasNext = !!docs[currentIndex + 1];
    // if (!hasNext) {
    //   this.navigationPage = page + 1;
    // }

  }
  get title(): string | null {
    return this.doc ? this.doc.title : null;
  }
  get regUser(): string | null {
    return this.doc ? this.doc.regUserId : null;
  }
  get regDate(): string | null {
    return this.doc ? this.doc.regDate.date() : null;
  }
  get readCount(): string {
    return this.doc ? new Intl.NumberFormat().format(this.doc.readCount) : '0';
  }
  get targetGroup(): string {
    return this.doc ? this.doc.targetGroup.join(',') : '';
  }
  get nextPath() {
    return this.nextDoc ? { path: `/my-page/board/${this.nextDoc._id}`, query: { ...this.queries, page: this.isPageLast ? +this.queries.page + 1 : this.queries.page }, title: this.nextDoc.title } : null;
  }
  get prevPath() {
    return this.prevDoc ? { path: `/my-page/board/${this.prevDoc._id}`, query: { ...this.queries, page: this.isPageFirst ? +this.queries.page - 1 : this.queries.page }, title: this.prevDoc.title } : null;
  }
  get likeCount(): string {
    return this.doc ? new Intl.NumberFormat().format(this.doc.likeCount || 0) : '0';
  }
  get specificUsers() : string[] {
    return this.doc ? this.doc.sendUserId : [];
  }
  get isNav(): boolean {
    const { isNav } = this.$route.query;
    return !!isNav;
  }
}
